import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import Resource from '../components/resources/index'

const IndexPage = () => {
	return(
		<Layout>
		  <SEO title="Resources" />
		  <Resource/>
		</Layout>
	)
}

export default IndexPage
