import React from 'react'
import {graphql, useStaticQuery, Link} from "gatsby"

import style from './resources.module.css';
import CatItem1 from '../../../images/resources/cat-item-1.png'
import CatItem2 from '../../../images/resources/cat-item-2.png'
import CatList from './categoriesList'
import RList from './resources-list'


const ResourceComp = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiResources {
			    edges {
			      node{
			        name
			        slug
			        excerpt
			        id
			      }
			    }
			}
		}
	`)
	let r = [];
	let c = [];
	query.allStrapiResources.edges.forEach(({node})=>{
		r.push(node);
	})
	return (
		<>
			<section className={style.resourcesSection}>
				<h3 className={style.resourcesTitle}>Resources</h3>
				<div className={style.container}>
					<CatList style={style}/>
					<div className={style.box2}>
						<div className={style.box2Container}>
							<div className={style.resourcesCatItem}>
								<img src={CatItem1} alt=""/>
							</div>
							<div className={style.resourcesCatItem}>
								<img src={CatItem2} alt=""/>
							</div>
						</div>
					</div>
				</div>
				<RList style={style} r={r}/>
			</section>
		</>
	)
}

export default ResourceComp