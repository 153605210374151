import React from 'react'
import ResourceCom from './resource'

const ResourceIndex = () => {
	return (
		<>
			<ResourceCom/>
		</>
	)
}

export default ResourceIndex